import { defineAsyncComponent } from 'vue';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./Edit/Index.vue'))
		},
		list: {
			config: {
			},
			component: defineAsyncComponent(() => import('./List/Index.vue'))
		},
		select: {
			panel: {
				width: '70%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {},
					payment: {
						config: {
							visible: false
						}
					},
					vet_clinic: {
						config: {
							visible: false
						}
					},
					departments: {
						config: {
							visible: false
						}
					}
				}
			}
		}
	}
})