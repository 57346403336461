import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		select: {
			config: {
				fields: {
					name: {},
					size: {},
					updated_at: {},
					created_at: {}
				}
			}
		}
	}
}