import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '50%'
			},
			component: defineAsyncComponent(() => import('./Edit/Index.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./List/Index.vue'))
		},
		select: {
			panel: {
				width: '70%',
				height: '500px'
			},
			config: {
				fields: {
					name: {
						config: {

						}
					},
				}
			}
		}
	}
}